import { Accordion, Anchor, Container, Text, Title } from "@mantine/core";
import { FiHelpCircle } from "react-icons/fi";

export function FAQ({ inApp = false }: { inApp?: boolean }) {
  return (
    <Container size="lg" py="xl">
      <div className="mb-8 text-center">
        <div className="flex items-center justify-center gap-3">
          <FiHelpCircle size={36} className="text-primary-500" />
          <Title order={2}>
            <span className="hidden md:inline">Frequently Asked Questions</span>
            <span className="md:hidden">FAQ</span>
          </Title>
        </div>
      </div>

      <Accordion>
        {inApp && (
          <Accordion.Item value="onboarding-call">
            <Accordion.Control>
              <Text fw={500}>What&apos;s the quickest way to get started?</Text>
            </Accordion.Control>
            <Accordion.Panel>
              <Anchor
                href="https://www.loom.com/share/6ad4c819402d414fb5990fc567c24664?sid=1efda733-5e00-47db-b69d-5c40c6da3a86"
                target="_blank"
                rel="noopener noreferrer"
                className="text-primary-600 hover:text-primary-700 font-medium"
              >
                Watch our 50-second quick start guide
              </Anchor>{" "}
              that explains how to have something ready for your students by
              tomorrow!
            </Accordion.Panel>
          </Accordion.Item>
        )}
        {inApp && (
          <Accordion.Item value="onboarding-call">
            <Accordion.Control>
              <Text fw={500}>
                What do I need to do to set up personalized review for my
                students?
              </Text>
            </Accordion.Control>
            <Accordion.Panel>
              <Text>To set up personalized review on Podsie:</Text>
              <ul className="list-disc mt-2 ml-6">
                <li className="mb-2">Set up a class</li>
                <li className="mb-2">
                  Share the class code with your students to enroll
                </li>
                <li className="mb-2">Publish the assignment to them</li>
              </ul>
              <Text mt="md">
                Questions they finish in an assignment will start cumulatively
                showing up in their &apos;Personal Review&apos; in a
                differentiated manner.
              </Text>
            </Accordion.Panel>
          </Accordion.Item>
        )}

        <Accordion.Item value="is-podsie-free">
          <Accordion.Control>
            <Text fw={500}>Is Podsie actually free?</Text>
          </Accordion.Control>
          <Accordion.Panel>
            <Text>
              Yes, Podsie is completely free! We&apos;re a part of{" "}
              <Anchor href="https://teachinglab.org/" target="_blank">
                Teaching Lab
              </Anchor>
              , a nonprofit committed to supporting teachers. Our work is funded
              through grants from organizations like the
              <Anchor
                href="https://www.nsf.gov/awardsearch/showAward?AWD_ID=2301130&HistoricalAwards=false"
                target="_blank"
                rel="noopener noreferrer"
                ml={5}
                mr={5}
              >
                National Science Foundation
              </Anchor>
              and the
              <Anchor
                href="https://tools-competition.org/winner/podsie/"
                target="_blank"
                rel="noopener noreferrer"
                ml={5}
              >
                Tools Competition
              </Anchor>
              .
            </Text>
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value="content-library">
          <Accordion.Control>
            <Text fw={500}>
              What courses are available in the Content Library?
            </Text>
          </Accordion.Control>
          <Accordion.Panel>
            <Text>
              Our content library is constantly expanding. Currently, we offer
              complete coverage for:
            </Text>
            <ul className="list-disc mt-2 ml-6">
              <li className="mb-2">
                <strong>8th Grade NY Intermediate Level Science</strong>
              </li>
            </ul>
            <Text mt="md">Coming end of March 2024:</Text>
            <ul className="list-disc mt-2 ml-6">
              <li className="mb-2">
                <Text component="span">
                  <strong>NY Regents Living Environment</strong>
                </Text>
              </li>
              <li className="mb-2">
                <Text component="span">
                  <strong>NY Regents Earth and Space Science</strong>
                </Text>
              </li>
            </ul>
            <Text mt="md">
              We&apos;ll continue releasing more courses based on teacher
              demand. If you have specific course requests, please email us at{" "}
              <Anchor href="mailto:hello@podsie.org">hello@podsie.org</Anchor>.
            </Text>
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value="learning-science">
          <Accordion.Control>
            <Text fw={500}>How does Podsie improve student learning?</Text>
          </Accordion.Control>
          <Accordion.Panel>
            <Text>
              Podsie is built on established learning science principles. We use{" "}
              <Anchor
                href="https://drive.google.com/file/d/1XaI9LSup8En-FHGAokgEeCWL68U69KBU/view?usp=sharing"
                target="_blank"
              >
                spacing and retrieval practice
              </Anchor>{" "}
              to help students better retain information over the long term.
              Research shows that spacing out practice over time and actively
              recalling information leads to stronger, more durable learning
              compared to traditional study methods.
            </Text>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </Container>
  );
}
